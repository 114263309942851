/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --primary-color: #4CAF50;
  --secondary-color: #555555;
  --background-color: #f5f6fa;
  --card-background: #ffffff;
  --border-color: #dee2e6;
  --hover-color: #f1f1f1;
  --button-hover: #45a049;
  --input-border: #ced4da;
  --modal-bg: rgba(0, 0, 0, 0.5);
  --error-color: #dc3545;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
}

.profile-page {
  min-height: 100vh;
  padding: 20px 0;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: var(--card-background);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.infludata-logo {
  height: 60px;
  margin-right: 20px;
}

.header-title {
  font-size: 32px;
  font-weight: 500;
  color: var(--secondary-color);
}

.back-button {
  margin-bottom: 20px;
  background-color: var(--secondary-color);
  border: none;
  color: var(--card-background);
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #333333;
}

h1.text-center {
  margin-bottom: 30px;
  color: var(--secondary-color);
}

.profile-pic {
  border-radius: 50%;
  display: block;
  margin: 0 auto 20px auto;
  width: 180px;
  height: 180px;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.table {
  background-color: var(--card-background);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.table th {
  background-color: #f7f7f7;
  color: var(--secondary-color);
  font-weight: 600;
  text-align: left;
}

.table td {
  color: #333333;
  vertical-align: middle;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover {
  background-color: var(--hover-color);
}

.form-control {
  border: 1px solid var(--input-border);
  border-radius: 5px;
  padding: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.scale-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 10px 0;
}


.scale-container::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}

.scale-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}


.scale-label::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background-color: #fff;
  margin-bottom: 5px;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type='radio']:checked + label::before {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.save-button {
  display: block;
  margin: 30px auto 0;
  background-color: var(--primary-color);
  border: none;
  color: #ffffff;
  padding: 12px 25px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
}

.save-button:hover {
  background-color: var(--button-hover);
  transform: translateY(-2px);
}

.uploaded-files {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.uploaded-file {
  background-color: #f8f9fa;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.uploaded-file:hover {
  border-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.3);
}

textarea {
  height: 150px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  transition: border-color 0.3s, box-shadow 0.3s;
  resize: vertical;
}

textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.modal-content {
  border-radius: 10px;
}

.modal-header, .modal-footer {
  border: none;
}

.modal-title {
  font-weight: 500;
  color: var(--secondary-color);
}

.modal-body {
  color: #333333;
}

.modal-footer .btn-primary {
  background-color: var(--primary-color);
  border: none;
  transition: background-color 0.3s;
}

.modal-footer .btn-primary:hover {
  background-color: var(--button-hover);
}

@media (max-width: 768px) {
  .profile-pic {
    width: 120px;
    height: 120px;
  }

  .header-title {
    font-size: 24px;
  }

  .back-button {
    padding: 8px 16px;
  }

  .save-button {
    width: 100%;
    padding: 10px 0;
  }

  .scale-container {
    flex-direction: column;
  }

  .scale-container::before {
    display: none;
  }

  .scale-label {
    margin-bottom: 10px;
  }
}
