/* SocialHandleInput.css */

.social-handle-input {
    position: relative;
  }
  
  .suggestions-dropdown {
    position: absolute;
    z-index: 990000;
    background: white;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;

  }
  
  .suggestion-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    z-index: 99990000;
  }
  
  .suggestion-item:hover {
    background-color: #f5f5f5;
  }
  