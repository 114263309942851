/* dragDrop.css */

.upload-section,
.uploaded-files-section {
  margin-top: 20px;
}

.uploaded-files-section h4 {
  margin-bottom: 15px;
}

.uploaded-files-section .list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.uploaded-files-section .list-group-item a {
  text-decoration: none;
  color: #007bff;
}

.uploaded-files-section .list-group-item a:hover {
  text-decoration: underline;
}

.uploaded-files-section .btn-outline-danger {
  border-color: transparent;
}
