.management-dropdown-item {
	color: black;
	background-color: white !important;
	width: 500px;
}

.management-dropdown-item:hover {
	background-color: #333333 !important; /* Dark grey */
	color: white !important;
}

.management-dropdown-item:active {
	background-color: #ffbb88 !important; /* Slight orange */
	color: black !important;
}

.management-dropdown-item.active,
.management-dropdown-item:active {
	background-color: #ffbb88 !important; /* Slight orange */
	color: black !important; /* Override Bootstrap's default active color */
}
