.login-container {
  position: relative;
}

.header-title {
  color: #f08d49;
}

.infludata-logo {
  height: 40px;
  margin-right: 10px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.login-form {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 5px;
  max-width: 400px;
  margin: 100px auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  width: 80%; /* Increase the width of the grey box */
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 80% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
  background-color: #ffffff;
  color: #252525;
  border: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

.btn:hover {
background-color: #e07a3c;
}

/* Add the following CSS to style the header */
header {
display: flex;
justify-content: center;
align-items: center;
padding: 10px 0;
background-color: #f7f7f7;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Additional styles for error message */
.error-message {
color: red;
margin-bottom: 10px;
}
