/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --instagram-color: #e17130;
  --tiktok-color: #69C9D0;
  --youtube-color: #FF0000;
  --primary-text-color: #333333;
  --secondary-text-color: #555555;
  --background-color: #f5f6fa;
  --white-color: #ffffff;
  --gray-color: #f1f1f1;
}

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
}

.hello-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: var(--white-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.infludata-logo {
  height: 50px;
  margin-right: 15px;
}

.header-title {
  font-size: 28px;
  font-weight: 500;
  color: var(--primary-text-color);
}

.form-container {
  max-width: 600px;
  width: 90%;
  margin: 40px auto 0 auto;
  background-color: var(--white-color);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.platform-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.platform-buttons {
  display: flex;
  gap: 15px;
}

.platform-button {
  width: 100px;
  color: var(--white-color);
  font-weight: 500;
  border: none;
  transition: background-color 0.3s;
}

.platform-button.instagram {
  background-color: var(--instagram-color);
}

.platform-button.tiktok {
  background-color: var(--tiktok-color);
}

.platform-button.youtube {
  background-color: var(--youtube-color);
}

.platform-button:hover {
  opacity: 0.9;
}

.platform-button.active {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 100.1);
}

.instructions {
  font-size: 20px;
  font-weight: 400;
  color: var(--secondary-text-color);
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  height: 45px;
  font-size: 16px;
}

.suggestions-dropdown {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions-dropdown .dropdown-item {
  font-size: 16px;
  padding: 10px 20px;
}

.creator-list-container {
  max-width: 1000px;
  margin: 40px auto;
  background-color: var(--white-color);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.creator-list-title {
  font-size: 24px;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-bottom: 30px;
  text-align: center;
}

.table {
  width: 100%;
  margin-bottom: 0;
}

.table th,
.table td {
  vertical-align: middle;
  text-align: center;
}

.table th {
  background-color: #f7f7f7;
  color: var(--primary-text-color);
  font-weight: 600;
}

.table tbody tr:hover {
  background-color: var(--gray-color);
}

.table .btn {
  padding: 5px 10px;
  font-size: 14px;
}

@media (max-width: 768px) {
  .form-container,
  .creator-list-container {
    padding: 20px;
  }

  .platform-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .platform-button {
    width: 100%;
  }

  .form-control {
    font-size: 14px;
  }

  .instructions {
    font-size: 18px;
  }

  .creator-list-title {
    font-size: 20px;
  }
}


.management-dropdown-item {
  background-color: rgb(255, 255, 255) !important;
}

.management-dropdown-item:hover,
.management-dropdown-item:active,
.management-dropdown-item.active {
  background-color: #f8f9fa !important;
}
